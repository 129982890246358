.main-container {
    margin: 2% 5%;
}

.product-container {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.logo-container {
    width: 18vh;
    filter: invert(1);
}

.logo-container-scrolled {
    width: 18vh;
    filter: none;
}

.header-name {
    color: #ffc107c2 !important;
}

.header-name2 {
    color: black;
}

.navber-container {
    background-color: white;
}

.blink-me {
    animation: blinker 2s linear infinite;
}


.mask {
    background-color: rgba(0, 0, 0, 0.5)
}

.home-bg-image {

    background-image: url('../src/assets/images/bg_1.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    height: 120vh;
    box-shadow: inset 0 0 0 2000px rgba(43, 42, 42, 0.48);


}

.navbar-brand {
    letter-spacing: 3px;
    font-size: 2rem;
    font-weight: 500;
}

.navbar-scroll .navbar-brand,
.navbar-scroll .nav-link,
.navbar-scroll .fa-bars {
    color: #fff;
}

.navbar-scroll {
    box-shadow: none;
}

.navbar-scrolled {
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
}

.navbar-scrolled .navbar-brand,
.navbar-scrolled .nav-link,
.navbar-scrolled .fa-bars {
    color: #4f4f4f;
}

.navbar-scrolled {
    background-color: #fff;
}

.display-1 {
    font-weight: 500 !important;
    letter-spacing: 40px;
}

.product-image {
    height: 45vh;
    background-color: #555555;
}

.home-container{
    height: 120vh;
    align-items: center;
}
@keyframes blinker {
    50% {
        opacity: 0;
    }
}

@media (max-width: 320px) {
    .logo-container {
        width: 12vh;
    }

    .logo-container-scrolled {
        width: 12vh;
    }

    .navbar-brand {
        font-size: 1.7rem;
    }
}

@media (width: 360px) {
    .logo-container {
        width: 11vh !important;
    }
    .product-image {
        height: 35vh !important;
    }
    .logo-container {
        width: 11vh !important;
    }
    .logo-container-scrolled {
        width: 11vh !important;
    }
}

@media (width: 430px) {
    #intro {
        height: auto !important;
    }
    .product-image {
        height: 35vh !important;
    }
    
}

@media (width: 390px) {
    #intro {
        height: auto !important;
    }
    .product-image {
        height: 35vh !important;
    }
}

@media (width: 412px) {
    #intro {
        height: auto !important;
    }
    .product-image {
        height: 35vh !important;
    }
}

@media (width: 414px) {
    #intro {
        height: auto !important;
    }
    .product-image {
        height: 35vh !important;
    }
}

@media (max-width: 450px) {
    #intro {
        height: 950px;
    }

    .logo-container {
        width: 12vh;
    }

    .logo-container-scrolled {
        width: 12vh
    }
}

@media (min-width: 550px) and (max-width: 750px) {
    #intro {
        height: 1100px !important;
    }
}

@media (width: 768px) {
    .home-bg-image {
        height: 70vh !important;
    }
    .home-container{
        height: 80vh;
        
    }
    .logo-container {
        width: 12vh;
    }

    .logo-container-scrolled {
        width: 12vh
    }
    .product-image {
        height: 30vh !important;
    }
}


@media (min-width: 800px) and (max-width: 990px) {
    #intro {
        height: 600px !important;
    }
}

@media (max-width: 991px) {
    .navbar-scroll {
        background-color: #fff;
    }

    .navbar-scroll .navbar-brand,
    .navbar-scroll .nav-link,
    .navbar-scroll .fa-bars {
        color: #4f4f4f !important;
    }
}

@media (min-width: 1600px) {
    .display-1 {
        font-size: 10rem;
    }
}